<template>
  <BCard
    v-if="Object.keys(publicationsPerYear).length > 0"
    header-tag="h2"
    header-bg-variant="ams-gray"
    header-border-variant="ams-header-orange"
    header-text-variant="black"
    header-class="border-bottom highlight-border"
    align="left"
    class="shadow mt-3"
  >
    <template slot="header">
      Publications per Year
    </template>
    <BRow>
      <BCol v-if="Object.keys(publicationsPerYear).length > 0">
        <span class="publication-years indent-xs">
          Data from {{ Object.keys(publicationsPerYear)[0] }} to {{ Math.min(Object.keys(publicationsPerYear)[Object.keys(publicationsPerYear).length - 1], latestYear) }}
        </span>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BTabs
          style="width:100%;"
        >
          <BTab
            title="Publications Graph"
            lazy
          >
            <template slot="title">
              <span title="Publications Graph">
                <span class="d-none d-md-inline-block">
                  Graph&nbsp;
                </span>
                <FontAwesomeIcon
                  role="presentation"
                  icon="chart-bar"
                  aria-hidden="true"
                />
              </span>
            </template>
            <Graph
              :data1="pubGraph"
            />
          </BTab>
          <BTab
            title="Publications Table"
          >
            <template slot="title">
              <span title="Publications Table">
                <span class="d-none d-md-inline-block">
                  Table&nbsp;
                </span>
                <FontAwesomeIcon
                  role="presentation"
                  icon="columns"
                  aria-hidden="true"
                />
              </span>
            </template>
            <h3 class="table-title">
              Publications per year
            </h3>
            <Msntable
              :rows="pubTable"
              :header="true"
            />
          </BTab>
          <BTab
            title="Publications Description"
          >
            <template slot="title">
              <span title="Description">
                <span class="d-none d-md-inline-block">
                  Description&nbsp;
                </span>
                <FontAwesomeIcon
                  role="presentation"
                  icon="info-circle"
                  aria-hidden="true"
                />
              </span>
            </template>
            <div class="indent-xs">
              <br>
              <p>Publications selected for listing in MathSciNet for the indicated year.</p>
              <br>
            </div>
          </BTab>
        </BTabs>
      </BCol>
    </BRow>
  </BCard>
</template>
<script>
// import Vue from 'vue'
// import { formatNumber } from '@/utils'
// import { formatName } from '@/utils'
// import URLGen from '@/utils/url-gen'
import { JournalAPI, SeriesAPI } from '@/js-api'

export default {
  name: 'ProfilePublicationsComponent',
  components: {
    // Card: () => import(/* webpackChunkName: "journal_card" */ '@/components/journal/JournalCard.vue'),
    Graph: () => import(/* webpackChunkName: "journal_graph" */ '@/components/journal/JournalGraph.vue'),
    Msntable: () => import(/* webpackChunkName: "journal_msntable" */ '@/components/journal/MsnTable.vue'),
  },
  // title: 'ProfilePublications',
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    seriesId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      publicationsPerYear: {
        // {year, qty}
      },
      pubGraph: {
        changeable: {
          title: 'Publications per year',
          x: [],
          y: [],
          axis: {
            x: 'Year',
            y: 'Publications',
          },
          label: [
            'Publications',
          ],
        },
        id: 'pub-graph',
        type: 'bar',
      },
      pubTable: [
        ['Year', 'Publications'],
      ],
    }
  },
  computed: {
    latestYear: function() {
      const currentDate = new Date()
      // currentDate.setMonth(currentDate.getMonth() - 18) // Citations are delayed
      return currentDate.getFullYear()
    },
    isSeries: function() {
      return (this.seriesId && this.seriesId > -1)
    },
  },
  mounted: function() {
    this.getPublications()
  },
  methods: {
    getPublications: async function() {
      let response
      if (this.isSeries) {
        response = await SeriesAPI.publications(this.seriesId)
      } else {
        response = await JournalAPI.publications(this.groupId)
      }

      let publications = []
      if (this.isSeries) {
        if (response.results.length === 0) {
          console.log('No Publications')
          return
        }
        publications = response.results[0].publicationsPerYear
      } else {
        if (response.length === 0) {
          console.log('No Publications')
          return
        }
        publications = response[0].publicationsPerYear
      }
      for (const i in publications) {
        this.$set(this.publicationsPerYear, publications[i].year, publications[i].publications)
      }
      this.updatePubGraph()
      this.updatePubTable()
      this.renderPublications = true
    },
    updatePubGraph: function() {
      for (const i in this.publicationsPerYear) {
        if (i > this.latestYear) {
          continue
        }
        this.pubGraph.changeable.x.push(i)
        this.pubGraph.changeable.y.push(this.publicationsPerYear[i])
      }
    },
    updatePubTable: function() {
      for (const i in this.publicationsPerYear) {
        if (i >= this.latestYear) {
          continue
        }
        // const urlArgs = {
        //   pubYear: i,
        // }
        // if (this.isSeries) {
        //   urlArgs.seriesId = this.seriesId
        // } else {
        //   urlArgs.groupId = this.groupId
        // }
        const query = `y:${i} ` + (this.isSeries ? `si:${this.seriesId}` : `gi:${this.groupId}`)

        this.pubTable.splice(1, 0,
          [
            i,
            {
              text: this.publicationsPerYear[i],
              // url: URLGen.publications(urlArgs), // add year
              to: {
                name: 'PublicationsSearch',
                query: {
                  query: query,
                },
              },
              attr: {
                title: 'Publications in ' + i,
              },
            },
          ]
        )
      }
    },
  },
}
</script>
